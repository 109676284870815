import React, { useState } from "react";
import "../../Styles/Study_with_us.css";
import Grid from "@mui/material/Grid";
import NoticeCarosel from "../../Component/Carosel/Notice_Carosel";
import { Typography,Button } from "@mui/material";

const Study_with_us = () => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const Study_with = {
    SU: {
      title: "Study With Us",
      description:"College is an exciting and transformative experience! It's a place where you can pursue your passions,gain knowledge, and prepare for your future career. In our college you will have the opportunity to explore different subjects, meet new people,and engage in various extracurricular activities. It's a time of growth, learning, and self-discovery. Our college is a higher education institution where students pursue undergraduate or postgraduate degrees in various fields of study. It typically offers a wide range of majors and programmes allowing students to specialise in their areas of interest.College campuses often provide a vibrant and diverse community where students can engage in extracurricular activities, join clubs, and participate in events.",
    },
  };

  const noticeBoard = [
    {
      title: "Notice Board: ",
        description:"Congratulations to all the staff menbers in INDIA TODAY'S 2024",
        logoPath:"https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/studywithus/law achivements/WhatsApp Image 2024-06-25 at 7.37.51 PM.jpeg",
      },
    {
    title: "Notice Board: ",
      description:"Re-accredited by NAAC with A Grade (II-Cycle)",
      logoPath:"https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Notice board/a-grade-1-removebg.png",
    },

   ]

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="studyWithUsStyles">
      <section className="container mt-4">
        <Grid container spacing={5}>
          <Grid item xs={10} md={6} className="p-5 studyDes">
          <Typography variant="h4" className="mb-4 studyTitle">{Study_with.SU.title}</Typography>
            <div>
              <p
                className="studyDescription m-0"
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  height: showFullDescription || window.innerWidth <= 767 ? "auto" : "100px",
                  overflow: showFullDescription || window.innerWidth <= 767 ? "visible" : "hidden",
                }}
              >
                {showFullDescription
                  ? Study_with.SU.description
                  : Study_with.SU.description.slice(0, 155) +
                    (Study_with.SU.description.length > 300 ? "..." : "")}
                <Button onClick={toggleDescription} className="studyButton">
                  {showFullDescription ? "Read Less" : "Read More"}
                </Button>
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ alignItems: "center"}}
            className="noticeBoard"
          >
            {/* Assuming Notice_Carousel is a valid component */}
            <NoticeCarosel data={noticeBoard} />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Study_with_us;