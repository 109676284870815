import React from "react";
import "../../Styles/Aboutus.css";
// import banner_img from "../../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import Qoute_icon from "../../Assets/Icons/qoute.svg";
import { Grid, Typography } from "@mui/material";
// import Achived from "../../Component/Achived_componet/Achived";
// import employee_img from "../../Assets/Images/empolyee.png";
import HCarosel from "../../Component/Carosel/Header_Carosel";
import KakaPhoto from "../../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import Divider from '@mui/material/Divider';

const About_us = () => {
  const employee_Data = [
    {
      employe_name: "Dr.G. Vivek Venkatswamy",
      designation: "CHAIRMAN",
      description: "MLA (CHENNURU)",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/dr-vivek.jpg",
    },
    {
      employe_name: "Dr.G. Vinod",
      designation: "SECRETARY",
      description: "MLA (BELLAMPALLI)",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/G-Vinod-Kumar.jpg",
    },
    { 
      employe_name: "Dr.G. Saroja Vivekanand",
      designation: "CORRESPONDENT",
      description: "Managing Director – Visaka Industries",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/g-saroja.jpg",
    },
    {
      employe_name: "G. RAMA",
      designation: "JOINT SECRETARY",
      description: "DBRAEI Joint secretary",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/g-rama.jpg",
    },
    {
      employe_name: "PV Ramana Kumar",
      designation: "JOINT SECRETARY",
      description: "DBRAEI Joint secretary",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/ramana-kumar.jpg",
    },

    /*
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },*/
  ];
  const employee_Data2 = [
    {
      employe_name: "Dr. N. Rishikanth",
      designation: "DIRECTOR ACADEMICS",
      description: "",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/Dr. N.Rishikanth.jpg",
    },
    {
      employe_name: "Dr. M Shekar",
      designation: "Principal",
      description: "",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/Dr. M Shekar.jpg",
    }
  ]


  return (
    <div style={{backgroundColor:"#DFE5F0"}}>
    <section>
       <HCarosel />
       <div className="About_heading container py-5">
        <div className="heading d-flex justify-content-center text-center">
          <sub>
            <img src={Qoute_icon} alt="Qoute_Icon"/>
          </sub>
          <Typography variant="h2"><strong>Education is the key to a better future.</strong></Typography>
        </div>
      </div>
      <div className="container">
        <div>
          <Grid container>
            <Grid
              item
              md={5}
              className="d-flex flex-column align-items-center"
            >
              <div className="Kaka_Div">
                <img
                  src={KakaPhoto}
                  className="img-fluid kaka_Image"
                  alt="Kaka"
                />
              </div>
              <div className="text-center mt-3  pb-5">
                <Typography variant="h5" className="p-2">Late Sri G Venkat Swamy</Typography>
                <small className="text-primary">Founder and Chairman</small>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="py-0">
                <div>
                  <Typography variant="h5">About The Founder</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">
                    Born on October 5, 1929, the late Sri G Venkat Swamy,
                    Founder and Chairman of Dr. B. R. Ambedkar Educational
                    Institutions was the visionary behind setting up of these
                    group of establishments. With an analytical mind, infinite
                    capacity for work, and a knack for human relations, he also
                    functioned as a veteran Congress leader and Ex-Union
                    minister for the Government of India.<br></br><br></br>
                    Popularly known as ‘Kaka’, his political career began in
                    1950 and spanned five decades. Kaka was a four-time Member
                    of Parliament and served his last term in 2004. One who
                    worked towards improving living conditions of the weaker
                    sections of the society, he was also the General Secretary
                    of the National Huts Union in Hyderabad, providing permanent
                    accommodation for 75,000 hut dwellers.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="background-lightblue">
        <div className="container py-5">
          <div>
            <Grid container>
              <Grid item xs={10} md={6}>
                <div>
                  <img
                    src={
                      "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/banners/Banner1.png"
                    }
                    width="100%"
                    className="img-fluid rounded"
                    alt="collage_Banner"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="px-4">
                  <div>
                  <Typography variant="h5">Started In 1973</Typography>
                  </div>
                  <div>
                  <Typography variant="body2">
                      Dr B R Ambedkar Educational Society began in 1973 by Sri G
                      Venkat Swamy and was inaugurated by the then Hon.
                      President of India late Sri V. V. Giri. The establishment
                      was later converted to a Public Education Trust that had
                      initially aimed to provide non-commercial, quality
                      education to the underprivileged children, particularly to
                      those from communities deemed as minorities by the
                      Government of India.
                    </Typography>
                    <br/>
                    <Typography variant="body2">Our vibrant infrastructure is a facilitator for the
                      effective delivery of our curriculum. Apart from the
                      common central facilities, the colleges have well-equipped
                      laboratories, lecture halls and seminar halls.</Typography>
                      <br/>
                      <Typography variant="body2">
                      The organization provides adequate infrastructural support
                      that contributes to our students’ physical fitness and
                      personality development through various sporting
                      activities, in turn, cultivating sportsman spirit, team
                      spirit, leadership and talent among them. Till date, over
                      1, 00,000 students have benefited from the institutions
                      and have gone to serve the nation in various capacities
                      like the administrative, law enforcement, health,
                      engineering and politics, among others.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <div>
              <Typography variant="h5">Campus</Typography>
            </div>
            <div>
            <Typography variant="body2">
                The campus is spread across 3 acres landscape known for its
                salubrious climate and presents a congenial atmosphere to pursue
                higher studies. Our vibrant infrastructure is a facilitator for
                the effective delivery of our curriculum. Apart from the common
                central facilities, the colleges have well-equipped
                laboratories, lecture halls and seminar halls.
              </Typography>
            </div>

            {/* <div className="position-relative pt-2 pb-5">
              <div className="position-absolute top-100 start-50 translate-middle mt-4 pt-5">
                <img
                  src={
                    "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/banners/Banner1.png"
                  }
                  className="rounded"
                  style={{ width: "100%", height: "255px"}}
                  alt="college_banner"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="position-realative pt-5 mt-5">
        <Achived />
      </div> */}
       <div className="background-light">
        <div className="container">
        <Typography variant="h5" className="py-3" textAlign="center">
          <Divider textAlign="center">Meet the Founders</Divider>
        </Typography>
          <Grid container>
            {employee_Data &&
              employee_Data.map((items, index) => {
                return (
                  <Grid item xs={12} md={3} key={index}>
                    <div className="p-2">
                      <div className="employee_img">
                        <img  className="employee_img" src={items.image_path} alt="Founder data"/>
                      </div>
                      <Typography component="div" className="mt-2">
                      <Typography variant="h6">{items.employe_name}</Typography>
                        <Typography component="div">
                          <Typography variant="subtitle2" className="text-primary m-0 fs-15">{items.designation}</Typography>
                          <Typography variant="body2" className="text-primary m-0 fs-15">{items.description}</Typography>
                        </Typography>
                      </Typography>

                    </div>
                  </Grid>
                );
              })}
          </Grid>
      </div>
      <div className="container" >
        <Typography variant="h5" className="py-4" textAlign="center">
        <Divider textAlign="center">Director and Principal</Divider>
        </Typography>
          <Grid container justifyContent="center" spacing={2}>
            {employee_Data2 &&
              employee_Data2.map((items, index) => {
                return (
                  <Grid item xs={12} md={3} key={index}>
                    <div className="px-1 py-5">
                      <div>
                        <img  className="employee_img" src={items.image_path} alt="Founder data"/>
                      </div>
                      <Typography component="div" className="mt-2">
                      <Typography variant="h6">{items.employe_name}</Typography>
                        <Typography component="div">
                          <Typography variant="subtitle2" className="text-primary m-0 fs-15">{items.designation}</Typography>
                          <Typography variant="body2" className="text-primary m-0 fs-15">{items.description}</Typography>
                        </Typography>
                      </Typography>

                    </div>
                  </Grid>
                );
              })}
          </Grid>
      </div></div>
     
    </section>
    </div>
  );
};

export default About_us;
